import request from '@/utils/request';
export var defaultOrdersProductsDispatchData = {
  id: 0,
  enabled: true,
  orderId: undefined,
  ordersDispatchId: undefined,
  quantity: 0,
  comments: null
};
export var getOrdersProductsDispatches = function getOrdersProductsDispatches(params) {
  return request({
    url: '/orders-products-dispatches',
    method: 'get',
    params: params
  });
};
export var createOrdersProductsDispatch = function createOrdersProductsDispatch(data) {
  return request({
    url: '/orders-products-dispatches',
    method: 'post',
    data: data
  });
};
export var getOrdersProductsDispatById = function getOrdersProductsDispatById(id) {
  return request({
    url: "/orders-products-dispatches/".concat(id),
    method: 'get'
  });
};
export var updateOrdersProductsDispat = function updateOrdersProductsDispat(id, data) {
  return request({
    url: "/orders-products-dispatches/".concat(id),
    method: 'patch',
    data: data
  });
};
export var listOrdersProductsDispatches = function listOrdersProductsDispatches(ordersDispatchId) {
  return request({
    url: "/orders-products-dispatches/list/".concat(ordersDispatchId),
    method: 'get'
  });
};
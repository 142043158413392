var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "empty-text": "No data"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              sortable: "custom",
              width: "80",
              align: "center",
              label: _vm.$t("table.id"),
              prop: "id"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "300px",
              prop: "ordersProductId",
              label: "Product"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(scope.row.ordersProduct.product.name) + " "
                    ),
                    _c("br"),
                    scope.row.ordersProduct.productsVariation
                      ? _c("small", [
                          _vm._v(
                            " (" +
                              _vm._s(
                                scope.row.ordersProduct.productsVariation.name
                              ) +
                              ") "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              align: "center",
              prop: "ordersProduct.quantity",
              label: "Qty Ordered"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "130px",
              align: "center",
              prop: "quantity",
              label: "Qty Dispatched"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.createdDate"),
              width: "200px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(
                            scope.row.createdTimestamp,
                            "DD-MM-YYYY hh:mm a"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "*",
              align: "center",
              prop: "comments",
              label: "Comments"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }